import React, { useState } from "react";
import usersimg from "../../assets/Userimg.png";
import left from "../../assets/left.png";
import right from "../../assets/right.png";
import quote from "../../assets/quote.png";
import userimgtwo from "../../assets/userimgtwo.png";
import userimgthree from "../../assets/userimgthree.png";
import userimgfour from "../../assets/userimgfour.png";

const testimonials = [
  {
    text: "CFSD has been an incredible partner in our mission to build sustainable futures for vulnerable communities. Their approach to development is holistic and community-driven, which ensures that projects are not just a short-term fix but a pathway to long-term success. We are proud to be associated with CFSD.",
    name: "Jane Cooper",
    occupation: "CEO",
    image: usersimg,
  },
  {
    text: "Learning with CFSD has been an invaluable learning experience. The hands-on approach to sustainable development, combined with the opportunity to work directly with community members, gave me insights that I could never have gained in a classroom.",
    name: "John Doe",
    occupation: "Developer",
    image: userimgtwo,
  },
  {
    text: "The Foundation for Sustainable Development understands the importance of local leadership in creating meaningful change. Their support has helped us develop our leadership skills and manage our own development initiatives.",
    name: "Emily Smith",
    occupation: "Manager",
    image: userimgthree,
  },
  {
    text: "FSD’s commitment to ethical and impactful development work is truly inspiring.",
    name: "Michael Brown",
    occupation: "Engineer",
    image: userimgfour,
  },
];

const TestimonialSlider = () => {
  const [currentIndices, setCurrentIndices] = useState([0, 1, 2]);

  const handleNext = () => {
    setCurrentIndices((prevIndices) => {
      return prevIndices.map((index) => (index + 1) % testimonials.length);
    });
  };

  const handlePrev = () => {
    setCurrentIndices((prevIndices) => {
      return prevIndices.map(
        (index) => (index - 1 + testimonials.length) % testimonials.length
      );
    });
  };

  return (
    <div className="flex flex-col items-center">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-center gap-4">
        {/* Card 1 */}
        <div
          data-aos="zoom-in"
          className="lg:w-[334px] lg:h-[460px] md:w-[300px] md:h-[460px] w-full h-[460px] relative bg-white rounded-lg py-8 px-4"
        >
          <div>
            <img className="w-12 h-12" src={quote} alt="Quote" />
            <p
              data-aos="flip-up"
              className="text-base text-[#0F172A] font-extralight font-Roboto pt-4"
            >
              {testimonials[currentIndices[0]].text}
            </p>
          </div>
          <div className="absolute bottom-8 left-4">
            <div className="flex items-center">
              <img
                src={testimonials[currentIndices[0]].image}
                alt={testimonials[currentIndices[0]].name}
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <p className="font-semibold text-gray-800">
                  {testimonials[currentIndices[0]].name}
                </p>
                <p className="text-sm text-gray-500">
                  {testimonials[currentIndices[0]].occupation}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div
          data-aos="zoom-out"
          className="lg:w-[334px] lg:h-[430px] md:w-[300px] md:h-[430px] w-full h-full relative bg-white lg:block md:block hidden rounded-lg py-8 px-4"
        >
          <div>
            <img className="w-12 h-12" src={quote} alt="Quote" />
            <p
              data-aos="flip-down"
              className="text-base text-[#0F172A] font-extralight font-Roboto pt-4"
            >
              {testimonials[currentIndices[1]].text}
            </p>
          </div>
          <div className="absolute bottom-8 left-4">
            <div className="flex items-center">
              <img
                src={testimonials[currentIndices[1]].image}
                alt={testimonials[currentIndices[1]].name}
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <p className="font-semibold text-gray-800">
                  {testimonials[currentIndices[1]].name}
                </p>
                <p className="text-sm text-gray-500">
                  {testimonials[currentIndices[1]].occupation}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div
          data-aos="zoom-in"
          className="lg:w-[334px] lg:h-[400px] w-full h-full relative bg-white lg:block md:hidden hidden rounded-lg py-8 px-4"
        >
          <div>
            <img className="w-12 h-12" src={quote} alt="Quote" />
            <p
              data-aos="flip-up"
              className="text-base text-[#0F172A] font-extralight font-Roboto pt-4"
            >
              {testimonials[currentIndices[2]].text}
            </p>
          </div>
          <div className="absolute bottom-8 left-4">
            <div className="flex items-center">
              <img
                src={testimonials[currentIndices[2]].image}
                alt={testimonials[currentIndices[2]].name}
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <p className="font-semibold text-gray-800">
                  {testimonials[currentIndices[2]].name}
                </p>
                <p className="text-sm text-gray-500">
                  {testimonials[currentIndices[2]].occupation}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex gap-2 w-full pt-5">
        <div className="cursor-pointer" onClick={handlePrev}>
          <img className="w-12 h-12" src={left} alt="Previous" />
        </div>
        <div className="cursor-pointer" onClick={handleNext}>
          <img className="w-12 h-12" src={right} alt="Next" />
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
