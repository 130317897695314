import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import eventhero from "../assets/eventhero.png";
import Aos from "aos";
import "aos/dist/aos.css";
import eventaname from "../assets/eventaname.png";
import longimg from "../assets/longimg.png";
import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";
import "aos/dist/aos.css";

const OurEvents = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div
        data-aos="zoom-in"
        className=" lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center border-main border-t"
      >
        <div className=" lg:w-3/5 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:text-7xl md:text-7xl text-6xl font-bold">
              Our Bootcamp Trainings
            </p>
            <p className=" text-base text-[#1B1B1B] lg:w-[490px] font-light font-DMSans">
              In time past, CEFCODEV had hosted several transformative Bootcamp
              Training events focused on equipping individuals with practical
              skills to drive community development which engaged participants
              on hands-on workshops, interactive sessions, and mentorship
              opportunities that empowered them to become change agents in their
              communities.
            </p>
          </div>
        </div>
        <div className=" lg:w-2/5 md:w-full w-full ">
          <img src={eventhero} alt="" />
        </div>
      </div>

      <div className="pt-16 lg:px-24 md:px-10 px-8">
        <div className="container mx-auto">
          {/* Title Section */}
          <div className="">
            <h1 className="text-4xl font-bold mb-4 font-Nunito">
              Lautech/Ejigbo Campus Bootcamp
            </h1>
            <p className="text-lg lg:w-[740px] text-gray-700 font-Nunito">
              The event was a stepping stone for many to embark on impactful
              projects, and its success continues to inspire ongoing initiatives
              within our foundation. Participants engaged in hands-on workshops,
              interactive sessions, and mentorship opportunities that empowered
              them to become change agents in their communities.
            </p>
          </div>

          {/* Main Content Section */}
          <div className="flex flex-col lg:flex-row items-center mt-2">
            {/* Image with CTA */}
            <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
              <img
                data-aos="fade-right"
                src={eventaname}
                alt="Event"
                className="rounded-lg shadow-lg"
              />
              {/* <div className="text-center mt-4">
                <h2 className="text-xl font-semibold text-blue-700">
                  Technology
                </h2>
                <button className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-lg">
                  ENTER
                </button>
                <p className="text-sm text-gray-500 mt-2">
                  click here for more information
                </p>
              </div> */}
            </div>

            {/* Text Sections */}
            <div className="lg:w-1/2 w-full lg:pl-8">
              <p
                data-aos="fade-right"
                className="text-lg text-gray-700 mb-4 font-Nunito"
              >
                At CEFCODEV, we are committed to equipping individuals and
                communities with the skills and knowledge they need to drive
                sustainable development. Our bootcamp training events are
                designed to provide hands-on, practical training that empowers
                participants to become leaders and change-makers in their
                communities.
              </p>
              {/* <p
                data-aos="fade-right"
                className="text-lg text-black font-bold mb-4 font-Nunito"
              >
                Non in nam pulvinar etiam a.
              </p> */}
              <p
                data-aos="fade-left"
                className="text-lg text-gray-700 mb-4 font-Nunito"
              >
                Each bootcamp focuses on a range of essential topics, from
                community development and leadership to technical skills and
                innovation. Participants have the opportunity to engage in
                interactive workshops, group discussions, and real-world
                projects that allow them to apply what they’ve learned in
                practical ways.
              </p>
              <p
                data-aos="fade-left"
                className="text-lg text-gray-700 mb-4 font-Nunito"
              >
                Our bootcamps are led by industry professionals, community
                leaders, and subject-matter experts who are passionate about
                creating lasting impact.
              </p>
            </div>
          </div>

          {/* Additional Text Section */}
          <div className="mt-2 text-lg text-gray-700">
            <span data-aos="fade-left" className="font-Nunito">
              Whether you are looking to develop your leadership skills, enhance
              your technical abilities, or make a difference in your community,
              our bootcamp training events are the perfect platform to get
              started.
              {/* <span className="text-lg text-blue-600 font-semibold font-Nunito">
                Pharetra hendrerit netus dignissim lorem molestie pulvinar ut
                leo praesent.
              </span> */}
            </span>
            {/* <p data-aos="fade-left" className="font-Nunito">
              Suspendisse ipsum ornare in amet. Id vestibulum velit malesuada
              dictum sed id eu. Interdum nec accumsan vel proin. In ipsum
              commodo purus lectus.
            </p>
            <p data-aos="fade-left" className="mt-4 font-Nunito">
              Euismod adipiscing varius turpis sed pellentesque sollicitudin
              diam egestas mollis.
            </p>
            <p data-aos="fade-left" className="mt-4 font-bold font-Nunito">
              Nullam ipsum amet nec elit amet tempor arcu purus felis.
            </p> */}
          </div>
        </div>
      </div>

      <div data-aos="zoom-in" className="pt-16 lg:px-24 md:px-10 px-8">
        <div>
          <img src={longimg} alt="" />
          <p className=" font-Nunito mt-4">
            Join us at our next bootcamp, and be part of a growing network of
            changemakers who are building a brighter future for their
            communities!
          </p>
        </div>
      </div>

      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default OurEvents;
