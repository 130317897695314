import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Button, { SecButton } from "../buttons";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import heroImg from "../../assets/heroimage.png";
import heroImgtwo from "../../assets/heroimagetwo.png";
import heroImgthree from "../../assets/heroimagethree.png";
import "../../style/style.css";
import angleleft from "../../assets/angleleft.png";
import angleright from "../../assets/angleright.png";

const HeroSlider = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const navigate = useNavigate();
  const swiperRef = useRef(null); // Create a ref for the Swiper instance

  const images = [
    {
      src: heroImg,
      text: "Building a brighter future, one sustainable step at a time.",
      subText:
        "The Cephas Foundation for Community Development (CEFCODEV) is a non-profit organization dedicated to fostering sustainable community-driven development.",
      buttonText: "Shop now",
      color: "#fff",
    },
    {
      src: heroImgtwo,
      text: "Building a community where everyone reach their full potential",
      subText:
        "We invest in people by providing training, resources, and support to enhance skills and leadership abilities.",
      buttonText: "Shop now",
      color: "#000",
    },
    {
      src: heroImgthree,
      text: "Building a community where everyone reach their full potential",
      subText:
        "We invest in people by providing training, resources, and support to enhance skills and leadership abilities.",
      buttonText: "Shop now",
      color: "#fff",
    },
  ];

  return (
    <div className="relative">
      <Swiper
        ref={swiperRef} // Assign the ref to the Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        navigation={{ nextEl: null, prevEl: null }} // Disable Swiper's default navigation
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="slide tw-relative">
            <div className="lg:flex md:grid grid lg:gap-0 md:gap-10 gap-10 lg:ml-6 items-center lg:relative lg:top-[-60px] justify-center">
              <div className="lg:w-[45%] md:w-full w-full grid gap-5">
                <div className="lg:relative lg:h-[207px]">
                  <p className="lg:absolute text-stroke top-0 lg:w-[772px] lg:pt-0 md:pt-10 pt-5 text-[#0F172A] Satoshi lg:text-[70px] md:text-8xl text-[55px] lg:leading-[76px] md:leading-none leading-[62px] font-bold">
                    {image.text}
                  </p>
                </div>
                <div className="lg:pr-6 lg:pt-3 md:pt-5 pt-0">
                  <p className="lg:w-[610px] text-[#0F172A] Slight text-lg font-extralight ">
                    {image.subText}
                  </p>
                </div>
                <div className="flex items-center gap-5 lg:pt-0 md:pt-10 pt-0">
                  <Button
                    onClick={() => navigate("/donate")}
                    text="Donate"
                    css={
                      "bg-main w-[176px] font-medium h-12 rounded-xl font-roboto text-sm text-white"
                    }
                  />
                  <SecButton
                    text="Watch Video"
                    css={"text-main font-Roboto text-base font-medium"}
                  />
                </div>
              </div>
              <div className="lg:w-[55%] md:w-full w-full">
                <img
                  src={image.src}
                  alt={`Slide ${index}`}
                  className="slide-img tw-rounded-lg"
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <div className="absolute lg:top-[40%] md:top-[20%] top-[20%] transform  -translate-y-1/2 left-[-14px] z-10">
        <button
          className="w-[11px] h-[32px] text-black"
          onClick={() => swiperRef.current.swiper.slidePrev()} // Go to the previous slide
        >
          <img
            className="lg:h-[30px] md:h-[20px] h-[15px] lg:w-[11.31px] md:w-[11px] w-[8px]"
            src={angleleft}
            alt="Previous"
          />{" "}
          {/* Custom left arrow image */}
        </button>
      </div>
      <div className="absolute lg:top-[40%] md:top-[20%] top-[20%]  transform -translate-y-1/2 lg:right-10 md:right-0 right-0 z-10">
        <button
          className="w-[11px] h-[35px] text-black"
          onClick={() => swiperRef.current.swiper.slideNext()} // Go to the next slide
        >
          <img
            src={angleright}
            className="lg:h-[30px] md:h-[20px] h-[15px] lg:w-[11.31px] md:w-[11px] w-[8px]"
            alt="Next"
          />{" "}
          {/* Custom right arrow image */}
        </button>
      </div>
    </div>
  );
};

export default HeroSlider;
// import React, { useEffect } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
// import Button, { SecButton } from "../buttons";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import { useNavigate } from "react-router-dom";
// import heroImg from "../../assets/heroimage.png";
// import heroImgtwo from "../../assets/heroimagetwo.png";
// import heroImgthree from "../../assets/heroimagethree.png";

// const HeroSlider = () => {
//   useEffect(() => {
//     Aos.init({
//       duration: 3000,
//     });
//   }, []);

//   const navigate = useNavigate();
//   //   const images = [Imageone, Imagetwo, Imagethree];
//   const images = [
//     {
//       src: heroImg,
//       text: "Building a brighter future, one sustainable step at a time.",
//       subText:
//         "The Cephas Foundation for Community Development (CEFCODEV) is a non-profit organization dedicated to fostering sustainable community-driven development. 💡 'Innovative solutions for a sustainable tomorrow.'",
//       buttonText: "Shop now",
//       color: "#fff",
//     },
//     {
//       src: heroImgtwo,
//       text: "Building a community where everyone reach their full potential",
//       subText:
//         "We invest in people by providing training, resources, and support to enhance skills and leadership abilities. This empowers communities to manage their own development sustainably.",
//       buttonText: "Shop now",
//       color: "#000",
//     },
//     {
//       src: heroImgthree,
//       text: "Building a community where everyone reach their full potential",
//       subText:
//         "We invest in people by providing training, resources, and support to enhance skills and leadership abilities. This empowers communities to manage their own development sustainably.",
//       buttonText: "Shop now",
//       color: "#fff",
//     },
//   ];

//   return (
//     <Swiper
//       spaceBetween={30}
//       centeredSlides={true}
//       loop={true}
//       autoplay={{
//         delay: 4000,
//         disableOnInteraction: false,
//       }}
//       pagination={{ clickable: true }}
//       modules={[Autoplay, Pagination]}
//     >
//       {images.map((image, index) => (
//         <SwiperSlide key={index} className="slide tw-relative">
//           <div className=" lg:flex md:grid grid lg:gap-0 md:gap-10 gap-10 items-center lg:relative lg:top-[-40px] justify-center">
//             <div className=" lg:w-[45%] md:w-full w-full grid gap-5">
//               <div className=" lg:relative lg:h-[207px]">
//                 <p className="text-stroke lg:absolute top-0 lg:w-[792px] lg:pt-0 md:pt-10 pt-5 text-[#0F172A] Satoshi lg:text-7xl md:text-8xl text-[55px] lg:leading-[76px] md:leading-none leading-[62px] font-bold">
//                   {image.text}
//                 </p>
//               </div>
//               <div className=" lg:pr-6 lg:pt-3 md:pt-5 pt-0">
//                 <p className=" lg:w-[610px] text-[#0F172A] Slight text-lg font-extralight ">
//                   {image.subText}
//                 </p>
//               </div>
//               <div className=" flex items-center gap-5 lg:pt-0 md:pt-10 pt-0">
//                 <Button
//                   onClick={() => navigate("/donate")}
//                   text="Donate"
//                   css={
//                     "bg-main w-[176px] font-medium h-12 rounded-xl font-roboto text-sm text-white"
//                   }
//                 />
//                 <SecButton
//                   text="Watch Video"
//                   css={" text-main font-Roboto text-base font-medium"}
//                 />
//               </div>
//             </div>
//             <div className=" lg:w-[55%] md:w-full w-full">
//               <img
//                 src={image.src}
//                 alt={`Slide ${index}`}
//                 className="slide-img tw-rounded-lg "
//               />
//             </div>
//           </div>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

// export default HeroSlider;
//
//
//
//
//

//////////// the one ill co back to
// import React, { useEffect, useRef } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/navigation";
// import Button, { SecButton } from "../buttons";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import { useNavigate } from "react-router-dom";
// import heroImg from "../../assets/heroimage.png";
// import heroImgtwo from "../../assets/heroimagetwo.png";
// import heroImgthree from "../../assets/heroimagethree.png";
// import "../../style/style.css";
// import angleleft from "../../assets/angleleft.png";
// import angleright from "../../assets/angleright.png";

// const HeroSlider = () => {
//   useEffect(() => {
//     Aos.init({ duration: 3000 });
//   }, []);

//   const navigate = useNavigate();
//   const swiperRef = useRef(null); // Create a ref for the Swiper instance

//   const images = [
//     {
//       src: heroImg,
//       text: "Building a brighter future, one sustainable step at a time.",
//       subText:
//         "The Cephas Foundation for Community Development (CEFCODEV) is a non-profit organization dedicated to fostering sustainable community-driven development.",
//       buttonText: "Shop now",
//       color: "#fff",
//     },
//     {
//       src: heroImgtwo,
//       text: "Building a community where everyone reach their full potential",
//       subText:
//         "We invest in people by providing training, resources, and support to enhance skills and leadership abilities.",
//       buttonText: "Shop now",
//       color: "#000",
//     },
//     {
//       src: heroImgthree,
//       text: "Building a community where everyone reach their full potential",
//       subText:
//         "We invest in people by providing training, resources, and support to enhance skills and leadership abilities.",
//       buttonText: "Shop now",
//       color: "#fff",
//     },
//   ];

//   return (
//     <div className="relative">
//       <Swiper
//         ref={swiperRef} // Assign the ref to the Swiper
//         spaceBetween={30}
//         centeredSlides={true}
//         loop={true}
//         autoplay={{
//           delay: 8000,
//           disableOnInteraction: false,
//         }}
//         modules={[Autoplay, Navigation]}
//       >
//         {images.map((image, index) => (
//           <SwiperSlide key={index} className="slide tw-relative">
//             <div className="lg:flex md:grid grid lg:gap-0 md:gap-10 gap-10 lg:ml-6 items-center lg:relative lg:top-[-60px] justify-center">
//               <div className="lg:w-[45%] md:w-full w-full grid gap-5">
//                 <div className="lg:relative lg:h-[207px]">
//                   <p className=" lg:absolute top-0 lg:w-[772px] lg:pt-0 md:pt-10 pt-5 text-[#0F172A] Satoshi lg:text-[70px] md:text-8xl text-[55px] lg:leading-[76px] md:leading-none leading-[62px] font-bold">
//                     {image.text}
//                   </p>
//                 </div>
//                 <div className="lg:pr-6 lg:pt-3 md:pt-5 pt-0">
//                   <p className="lg:w-[610px] text-[#0F172A] Slight text-lg font-extralight ">
//                     {image.subText}
//                   </p>
//                 </div>
//                 <div className="flex items-center gap-5 lg:pt-0 md:pt-10 pt-0">
//                   <Button
//                     onClick={() => navigate("/donate")}
//                     text="Donate"
//                     css={
//                       "bg-main w-[176px] font-medium h-12 rounded-xl font-roboto text-sm text-white"
//                     }
//                   />
//                   <SecButton
//                     text="Watch Video"
//                     css={"text-main font-Roboto text-base font-medium"}
//                   />
//                 </div>
//               </div>
//               <div className="lg:w-[55%] md:w-full w-full">
//                 <img
//                   src={image.src}
//                   alt={`Slide ${index}`}
//                   className="slide-img tw-rounded-lg"
//                 />
//               </div>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>

//       {/* Custom Navigation Buttons */}
//       <div className="absolute top-[40%] transform  -translate-y-1/2 left-[-14px] z-10">
//         <button
//           className="swiper-button-prev w-[11px] h-[35px] text-black"
//           onClick={() => swiperRef.current.swiper.slidePrev()} // Go to the previous slide
//         >
//           <img className="h-[35px] w-[11.31px]" src={angleleft} alt="" />{" "}
//           {/* Use a Unicode character for the left arrow */}
//         </button>
//       </div>
//       <div className="absolute top-[40%] transform -translate-y-1/2 right-10 z-10">
//         <button
//           className="swiper-button-next w-[11px] h-[35px] text-black"
//           onClick={() => swiperRef.current.swiper.slideNext()} // Go to the next slide
//         >
//           <img src={angleright} className=" h-[35px] w-[11.31px]" alt="" />{" "}
//           {/* Use a Unicode character for the right arrow */}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default HeroSlider;

//
//
//
//
//
//
// import React, { useEffect, useRef } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination, Navigation } from "swiper/modules"; // Import Navigation
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
// import "swiper/css/navigation"; // Import navigation styles
// import Button, { SecButton } from "../buttons";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import { useNavigate } from "react-router-dom";
// import heroImg from "../../assets/heroimage.png";
// import heroImgtwo from "../../assets/heroimagetwo.png";
// import heroImgthree from "../../assets/heroimagethree.png";

// const HeroSlider = () => {
//   const swiperRef = useRef(null); // Swiper reference

//   useEffect(() => {
//     Aos.init({
//       duration: 3000,
//     });
//   }, []);

//   const navigate = useNavigate();
//   const images = [
//     {
//       src: heroImg,
//       text: "Building a brighter future, one sustainable step at a time.",
//       subText:
//         "The Cephas Foundation for Community Development (CEFCODEV) is a non-profit organization dedicated to fostering sustainable community-driven development. 💡 'Innovative solutions for a sustainable tomorrow.'",
//       buttonText: "Shop now",
//       color: "#fff",
//     },
//     {
//       src: heroImgtwo,
//       text: "Building a community where everyone reach their full potential",
//       subText:
//         "We invest in people by providing training, resources, and support to enhance skills and leadership abilities. This empowers communities to manage their own development sustainably.",
//       buttonText: "Shop now",
//       color: "#000",
//     },
//     {
//       src: heroImgthree,
//       text: "Building a community where everyone reach their full potential",
//       subText:
//         "We invest in people by providing training, resources, and support to enhance skills and leadership abilities. This empowers communities to manage their own development sustainably.",
//       buttonText: "Shop now",
//       color: "#fff",
//     },
//   ];

//   const handleNext = () => {
//     swiperRef.current?.swiper.slideNext(); // Slide to next
//   };

//   const handlePrev = () => {
//     swiperRef.current?.swiper.slidePrev(); // Slide to previous
//   };

//   return (
//     <div className="relative">
//       <Swiper
//         ref={swiperRef} // Assign Swiper reference
//         spaceBetween={30}
//         centeredSlides={true}
//         loop={true}
//         autoplay={{
//           delay: 6000, // Slow down the autoplay delay to 6 seconds
//           disableOnInteraction: false,
//         }}
//         speed={1500} // Adjust the slide transition speed (slower)
//         pagination={false} // Disable dots
//         modules={[Autoplay, Pagination, Navigation]} // Include Navigation module
//       >
//         {images.map((image, index) => (
//           <SwiperSlide key={index} className="slide tw-relative">
//             <div className="lg:flex md:grid grid lg:gap-0 md:gap-10 gap-10 items-center lg:relative lg:top-[-40px] justify-center">
//               <div className="lg:w-[45%] md:w-full w-full grid gap-5">
//                 <div className="lg:relative lg:h-[207px]">
//                   <p className="text-stroke lg:absolute top-0 lg:w-[792px] lg:pt-0 md:pt-10 pt-5 text-[#0F172A] Satoshi lg:text-7xl md:text-8xl text-[55px] lg:leading-[76px] md:leading-none leading-[62px] font-bold">
//                     {image.text}
//                   </p>
//                 </div>
//                 <div className="lg:pr-6 lg:pt-3 md:pt-5 pt-0">
//                   <p className="lg:w-[610px] text-[#0F172A] Slight text-lg font-extralight ">
//                     {image.subText}
//                   </p>
//                 </div>
//                 <div className="flex items-center gap-5 lg:pt-0 md:pt-10 pt-0">
//                   <Button
//                     onClick={() => navigate("/donate")}
//                     text="Donate"
//                     css={
//                       "bg-main w-[176px] font-medium h-12 rounded-xl font-roboto text-sm text-white"
//                     }
//                   />
//                   <SecButton
//                     text="Watch Video"
//                     css={" text-main font-Roboto text-base font-medium"}
//                   />
//                 </div>
//               </div>
//               <div className="lg:w-[55%] md:w-full w-full">
//                 <img
//                   src={image.src}
//                   alt={`Slide ${index}`}
//                   className="slide-img tw-rounded-lg"
//                 />
//               </div>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>

//       {/* Custom Navigation Arrows */}
//       <div className="absolute top-[50%] left-2 transform -translate-y-1/2 cursor-pointer">
//         <button onClick={handlePrev} className="swiper-button-prev text-2xl">
//           ←
//         </button>
//       </div>
//       <div className="absolute top-[50%] right-2 transform -translate-y-1/2 cursor-pointer">
//         <button onClick={handleNext} className="swiper-button-next text-2xl">
//           →
//         </button>
//       </div>
//     </div>
//   );
// };

// export default HeroSlider;
