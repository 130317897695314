// import React from "react";
// import Navbar from "../components/navbar/Navbar";

// const OurPartners = () => {
//   return (
//     <div>
//       <Navbar />
//     </div>
//   );
// };

// export default OurPartners;
import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import hand from "../assets/hand.png";
import LiteArtlogo from "../assets/LiteArtlogo.png";
import WhatsAppImage from "../assets/WhatsAppImage.png";
import Derek from "../assets/DEREKFOODS.png";
import cepahsimg from "../assets/cepahsimg.png";
import contmap from "../assets/contmap.png";
import bsaimage from "../assets/bsaimage.png";
import "aos/dist/aos.css";
import Aos from "aos";
import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";
import { IoIosMail } from "react-icons/io";
import {
  FaBehance,
  FaDribbble,
  FaFacebookSquare,
  FaLinkedin,
  FaPhone,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";

const OurPartners = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  return (
    <div className=" overflow-x-hidden">
      <div className="border-[#E2E8F0] border-b">
        <Navbar />
      </div>

      <div
        data-aos="zoom-in"
        className=" relative top-[-60px] lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center"
      >
        <div className=" lg:w-1/2 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:text-[65px] md:text-7xl text-6xl font-semibold">
              United for Progress; Together We Thrive!
            </p>
            <p className=" text-base text-[#1B1B1B] lg:w-[490px] font-light font-DMSans">
              Together, we are transforming lives and building resilient
              communities. With the support of our collaborators, we drive
              impactful initiatives that foster sustainable development and
              create lasting change. Join us in celebrating the power of
              collaboration!
            </p>
          </div>
        </div>
        <div className=" lg:w-1/2 md:w-full w-full ">
          <img src={hand} alt="" />
        </div>
      </div>

      <div className=" lg:pl-24 lg:pr-0 md:px-10 px-8 gap-10 pt-16 lg:flex md:grid grid">
        <div className="  lg:w-[35%]">
          <p className=" text-[#4caf50] text-xl font-Roboto font-semibold">
            Meet Our Outstanding Partners
          </p>
          <p className=" font-bold text-[#0f172a] text-5xl ">Our Partners</p>
          <p className=" text-[#A1A1B0] font-Nunito text-base mt-5">
            We are proud to work with a diverse group of organizations and
            individuals who share our vision for community empowerment.
          </p>
        </div>
      </div>
      <div className=" lg:pl-24 lg:pr-0 md:px-10 px-8 gap-10 pt-10 lg:flex md:grid grid justify-between">
        <div
          data-aos="fade-right"
          className=" flex items-center justify-betweenn gap-5 overflow-x-scroll overflow-y-hidden h-[200px]"
        >
          <img className=" w-[200px]" src={LiteArtlogo} alt="" />
          <img className=" w-[200px]" src={WhatsAppImage} alt="" />
          <img className=" w-[200px]" src={Derek} alt="" />
          <img className=" w-[200px]" src={cepahsimg} alt="" />
          <img className=" w-[200px]" src={bsaimage} alt="" />
        </div>
      </div>

      {/* Contact */}

      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default OurPartners;
