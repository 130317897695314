import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import contacthero from "../assets/contacthero.png";
import contmap from "../assets/contmap.png";
import "aos/dist/aos.css";
import Aos from "aos";
import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";
import { IoIosMail } from "react-icons/io";
import {
  FaBehance,
  FaDribbble,
  FaFacebookSquare,
  FaLinkedin,
  FaPhone,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  return (
    <div className=" overflow-x-hidden">
      <div className="border-[#E2E8F0] border-b">
        <Navbar />
      </div>

      <div
        data-aos="zoom-in"
        className=" relative top-[-60px] lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center"
      >
        <div className=" lg:w-1/2 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:text-7xl md:text-7xl lg:mt-0 md:mt-20 mt-0 text-6xl font-semibold">
              We look forward to connecting with you
            </p>
            <p className=" text-base text-[#1B1B1B] lg:w-[490px] font-light font-DMSans">
              We’d love to hear from you! Whether you have a question, want to
              get involved, or are interested in partnering with us, here’s how
              you can reach out:
            </p>
          </div>
        </div>
        <div className=" lg:w-1/2 md:w-full w-full ">
          <img src={contacthero} alt="" />
        </div>
      </div>

      <div className=" lg:pl-24 lg:pr-0 md:px-10 px-8 gap-10 pt-16 lg:flex md:grid grid justify-center">
        <div className=" lg:w-3/5 md:w-full w-full ">
          <div data-aos="fade-right">
            <p className="text-stroke font-DMSans text-2xl">Get in Touch</p>
            <p className=" text-[#797897] text-base font-Nunito lg:w-[450px]">
              We’d love to hear from you! Whether you have a question, want to
              get involved, or are interested in partnering with us, here’s how
              you can reach out:
            </p>
          </div>

          <div className="">
            <div data-aos="fade-right" className=" pt-10">
              <p className=" text-[#797897] text-sm font-DMSans font-bold">
                E-MAIL
              </p>

              <p className=" text-main pt-2 lg:text-4xl md:text-4xl text-2xl font-DMSans font-bold">
                cephas@foundation.org
              </p>
            </div>
            <div data-aos="fade-right" className=" pt-10">
              <p className=" text-[#797897] text-sm font-DMSans font-bold">
                Phone
              </p>

              <p className=" text-main pt-2 lg:text-4xl md:text-4xl text-2xl font-DMSans font-bold">
                +234 707 986 9544
              </p>
            </div>
            <div data-aos="fade-right" className=" pt-10">
              <p className=" text-[#797897] text-sm font-DMSans font-bold">
                Address
              </p>

              <p className=" lg:w-[504px] pt-2 text-main lg:text-4xl md:text-4xl text-2xl font-DMSans font-bold">
                Room 2, Cephas ICT HUB Building Adjacent Jolanis, Ogbomosho, Oyo
                State, Nigeria.
              </p>
            </div>
          </div>
        </div>
        <div className=" lg:w-2/5 md:w-full w-full ">
          <img data-aos="zoom-in" src={contmap} alt="" />
        </div>
      </div>

      {/* Contact */}
      <div className=" lg:px-36 md:px-10 px-8 container gap-20 flex flex-col lg:flex-row justify-between items-start">
        {/* Contact Form */}
        <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
          <h2
            data-aos="fade-right"
            className="text-4xl  text-[#1E0E62] font-bold font-DMSans mb-4"
          >
            Drop Us a Line
          </h2>
          <p
            data-aos="fade-right"
            className="text-[#706ea9] font-DMSans text-base mb-6"
          >
            We are always looking for a next great project
          </p>
          <form data-aos="fade-right">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm text-[#1E0E62] font-DMSans mb-2 font-semibold"
              >
                E-MAIL
              </label>
              <input
                type="email"
                id="email"
                placeholder="Your email"
                className="w-full border border-gray-300 rounded-full p-3 focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm text-[#1E0E62] font-DMSans mb-2 font-semibold"
              >
                MESSAGE
              </label>
              <textarea
                id="message"
                placeholder="Drop us a line"
                className="w-full border border-gray-300 rounded-lg p-3 h-32 focus:outline-none focus:border-indigo-500"
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm text-[#1E0E62] font-DMSans mb-2 font-semibold"
              >
                NAME
              </label>

              <div className=" flex items-center gap-5">
                <input
                  type="text"
                  id="name"
                  placeholder="Your name"
                  className="w-full border border-gray-300 rounded-full p-3 focus:outline-none focus:border-indigo-500"
                />

                <button
                  type="submit"
                  className="bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition duration-300"
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Contact Information */}
        <div className="lg:w-1/2 w-full">
          <h2
            data-aos="fade-left"
            className="text-4xl  text-[#1E0E62] font-bold font-DMSans mb-4"
          >
            Get in Touch
          </h2>
          <p
            data-aos="fade-left"
            className="text-[#706ea9] font-DMSans text-base mb-2"
          >
            For more information on our programs, partnerships, or how to get
            involved, please contact us at:
          </p>
          <div data-aos="fade-left" className="mb-4">
            <p className="text-lg font-DMSans text-[#1E0E62] flex items-center gap-2 font-medium">
              <FaPhone size={10} /> +234 707 986 9544
            </p>
            <p className="text-lg font-DMSans text-[#1E0E62] flex items-center gap-2 font-medium">
              <IoIosMail size={16} /> cefcodev4africa@gmail.com
            </p>
          </div>
          <div data-aos="fade-left" className="mb-4">
            <h3 className="text-lg font-DMSans font-semibold text-[#1E0E62] mb-2">
              We are on Socials
            </h3>
            <div className="lg:grid-cols-2 md:grid-cols-2 grid-cols-1 border-t border-t-[#706ea9] gap-3 grid lg:w-[400px] items-center ">
              <a href="https://www.facebook.com/profile.php?id=61566641077126">
                <div className=" flex items-center gap-4">
                  <FaFacebookSquare />
                  <p className=" text-base text-[#706ea9] font-DMSans">
                    Facebook
                  </p>
                </div>
              </a>

              {/* <div className=" flex items-center gap-4">
                <FaDribbble />

                <p className="text-base text-[#706ea9] font-DMSans">Dribbble</p>
              </div> */}
              <a href="https://x.com/cefcodev">
                <div className=" flex items-center gap-4">
                  <FaTwitter />

                  <p className="text-base text-[#706ea9] font-DMSans">
                    Twitter
                  </p>
                </div>
              </a>

              {/* <div className=" flex items-center gap-4">
                <FaBehance />

                <p className="text-base text-[#706ea9] font-DMSans">Behance</p>
              </div> */}
              {/* <div className=" flex items-center gap-4">
                <FaPinterest />

                <p className="text-base text-[#706ea9] font-DMSans">
                  Pinterest
                </p>
              </div> */}
              <a href="https://www.linkedin.com/in/cefcodev/">
                <div className=" flex items-center gap-4">
                  <FaLinkedin />

                  <p className="text-base text-[#706ea9] font-DMSans">
                    LinkedIn
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div data-aos="fade-left" className="mb-4 pt-5">
            <h3 className="text-lg font-DMSans font-semibold text-[#1E0E62] mb-2">
              Office Hours
            </h3>
            <p className="text-[#706ea9] font-DMSans text-base mb-6">
              Monday – Friday: 9:00 AM – 5:00 PM (EST)
              <br />
              Saturday – Sunday: Closed
            </p>
          </div>
          <p
            data-aos="fade-left"
            className="text-[#706ea9] font-DMSans text-base mb-6 font-medium"
          >
            We look forward to connecting with you!
          </p>
        </div>
      </div>
      {/* Contact */}

      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
