import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import eventhero from "../assets/eventhero.png";
import Button from "../components/buttons";
import Events from "../components/home/events";

import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";
import evone from "../assets/evone.png";
import evtwo from "../assets/evtwo.png";
import evthree from "../assets/evthree.png";
import evfour from "../assets/evfour.png";
import evfive from "../assets/evfive.png";
import evsix from "../assets/evsix.png";
import Aos from "aos";
import "aos/dist/aos.css";

const EventsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  const events = [
    {
      img: evone,
      eventName: "2020 lautech bootcamp",
      desc: "The event was a stepping stone for many to embark on impactful projects, and its success continues to inspire ongoing initiatives within our foundation. Participants engaged in hands-on workshops, interactive sessions, and mentorship opportunities that empowered them to become change agents in their communities.",
      btn: "Learn More",
    },
    {
      img: evtwo,
      eventName: "2019 LAUTECH BOOTCAMP TRAINING",
      desc: "In 2019, CEFCODEV hosted a transformative Bootcamp Training event focused on equipping individuals with practical skills to drive community development. Participants engaged in hands-on workshops, interactive sessions, and mentorship opportunities that empowered them to become change agents in their communities. ",
      btn: "Learn More",
    },
    {
      img: evthree,
      eventName: "2019 BOOTCAMP TRAINING",
      desc: "The 2019 Bootcamp Training proved to be a transformative experience for participants, who engaged in immersive skill-building workshops and interactive sessions. Under the mentorship of industry experts, attendees acquired practical knowledge and established valuable connections, empowering them to become catalysts for positive change within their communities.",
      btn: "Learn More",
    },
    {
      img: evfour,
      eventName: "2024 ejigbo campus bootcamp",
      desc: "The 2024 Ejigbo Campus Bootcamp inspired future leaders through hands-on workshops and guidance from experienced mentors. Participants learned important skills and made valuable connections, leaving ready to make a positive impact in their communities.",
      btn: "Learn More",
    },
    {
      img: evfive,
      eventName: "outreach trainingS",
      desc: "The event was a stepping stone for many to embark on impactful projects, and its success continues to inspire ongoing initiatives within our foundOur Outreach Trainings equip community members with essential skills to tackle local challenges. Through interactive sessions, participants learn about entrepreneurship, and sustainabilityand mentorship opportunities",
      btn: "Learn More",
    },
    {
      img: evsix,
      eventName: "2024 cefcodev bootcamp",
      desc: "The 2024 CEFCODEV Bootcamp focused on developing essential tech skills for participants. Attendees learned about coding, digital marketing, data analysis. and many relevant courses. This immersive experience not only equipped them with valuable skills but also inspired collaboration, empowering them to make a positive impact in their communities.",
      btn: "Learn More",
    },
  ];
  return (
    <div className=" overflow-x-hidden">
      <div className="border-[#E2E8F0] border-b">
        <Navbar />
      </div>
      <div
        data-aos="zoom-in"
        className=" lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center"
      >
        <div className=" lg:w-3/5 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:text-7xl md:text-7xl text-6xl font-bold">
              Welcome to our Events Page
            </p>
            <p className="  Slight font-extralight text-lg text-[#1B1B1B] lg:w-[500px] font-DMSans">
              tay updated on the latest happenings, conferences, workshops, and
              seminars hosted or sponsored by us. Our events bring together
              industry leaders, innovators, and enthusiasts to share knowledge,
              network, and inspire new ideas
            </p>
          </div>
        </div>
        <div className=" lg:w-2/5 md:w-full w-full ">
          <img src={eventhero} alt="" />
        </div>
      </div>

      <div className=" lg:px-24 md:px-10 px-8 pt-16 pb-10">
        <div className="">
          <p
            data-aos="fade-right"
            className="text-stroke text-[#1B1B1B] Satoshi lg:text-4xl md:text-4xl text-3xl font-extrabold"
          >
            Our Seasoned Events
          </p>
          <p
            data-aos="fade-left"
            className=" pt-5 text-[#1b1b1b] font-light text-xl lg:w-[760px]"
          >
            Whether you're looking to advance your skills, gain insights into
            the latest trends, or simply connect with like-minded individuals,
            we have something for everyone. Check out our recent and upcoming
            events below:
          </p>
        </div>
        <div data-aos="zoom-in" className=" pt-5 grid justify-center">
          <Events events={events} />

          <div className="   flex items-center justify-center pt-10">
            <Button
              text="View all"
              css={
                "text-[#1E0E62] w-44 h-14 text-lg border border-[#1E0E62] rounded-full"
              }
            />
          </div>
        </div>
      </div>
      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default EventsPage;
