import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const faqs = [
  {
    question: "What services does CEFCODEV provide?",
    answer:
      "CEFCODEV offers training, capacity building, volunteer opportunities, disaster relief, and entrepreneurship support to foster community development.",
  },
  {
    question: "How can I get involved with CEFCODEV?",
    answer:
      " is accessible to a wide range of organizations, including development partners, private companies, government agencies, and other geospatial-based entities operating in low- and middle-income countries (LMICs).",
  },
  {
    question: "Who are CEFCODEV target/audience? ",
    answer:
      " is currently offered as a free service for invited organizations.",
  },
  {
    question: "Are CEFCODEV programs free? ",
    answer:
      "Administrators oversee the addition and identification of new stakeholders, with users also able to recommend potential contacts within their networks.",
  },
  {
    question: "How does CEFCODEV respond to disaster situations? ",
    answer:
      "prioritizes data security with robust measures in place to protect user information and ensure compliance with privacy regulations.",
  },
];

const FAQItem = ({ faq, isOpen, onToggle }) => (
  <div
    data-aos="fade-right"
    className="border px-4 rounded-lg mb-4 bg-white lg:w-[1050px] md:w-full w-full relative"
  >
    <div className="flex justify-between items-center py-4 cursor-pointer">
      <h4 className="font-medium">{faq.question}</h4>
      <span onClick={onToggle} className=" absolute right-2">
        {isOpen ? "-" : "+"}
      </span>
    </div>
    {isOpen && <p className="pb-4 text-gray-600">{faq.answer}</p>}
  </div>
);

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  return (
    <div className="">
      <div data-aos="fade-right" className=" justify-center items-center grid">
        {" "}
        <h2 className="text-stroke lg:text-4xl md:text-3xl text-xl text-[#2F1893] font-bold mb-4 font-Inter">
          Frequently asked questions
        </h2>
      </div>
      <div
        data-aos="fade-left"
        className=" justify-center items-center grid font-Inter"
      >
        {" "}
        <p className="text-[#475569] lg:w-[900px] text-center pt-3 font-Nunito  lg:text-xl md:text-base text-sm mb-8">
          For more information about our work, projects, and how you can
          contribute, please visit our get in touch with us.
        </p>
      </div>

      {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          faq={faq}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default FAQ;
