import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import donatehero from "../assets/donatehero.png";
import accimg from "../assets/accimg.png";
import donateimg from "../assets/donateimg.png";
import Button from "../components/buttons";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import Aos from "aos";
import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";

const Donate = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  return (
    <div>
      <div className="border-[#E2E8F0] border-b">
        <Navbar />
      </div>
      <div
        data-aos="zoom-in"
        className=" lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center"
      >
        <div className=" lg:w-3/5 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:w-[650px] lg:text-7xl md:text-7xl text-6xl font-bold">
              Get Involved, Make a Donation
            </p>
            <p className=" text-base text-[#1B1B1B] lg:w-[550px] font-light font-DMSans">
              At CFSD, we believe that everyone has a role to play in creating a
              more sustainable and equitable world. Whether you’re interested in
              volunteering, donating, or partnering with us, there are many ways
              to get involved and make a difference. Together, we can build a
              brighter future for all.
            </p>
          </div>
        </div>
        <div className=" lg:w-2/5 md:w-full w-full ">
          <img src={donatehero} alt="" />
        </div>
      </div>

      <div className=" lg:flex md:grid grid justify-center items-center py-16 mt-10 gap-10 bg-[#2F1893] lg:pl-36 lg:pr-24 md:px-10 px-8">
        <div className=" lg:1/2 w-full">
          <div>
            <p
              data-aos="fade-left"
              className=" text-white font-DMSans text-4xl font-bold"
            >
              Account Details
            </p>
            <div className="pt-5">
              <div
                data-aos="fade-left"
                className="flex flex-wrap items-center gap-1 text-white font-DMSans text-xl font-medium"
              >
                <p>Account Name: </p>
                <p>CEPHAS ICT HUB</p>
              </div>
            </div>
            <div className="pt-2">
              <div
                data-aos="fade-left"
                className=" flex flex-wrap items-center gap-1 text-white font-DMSans text-xl font-medium"
              >
                <p>Account Number: </p>
                <p> 1619358696</p>
              </div>
            </div>
            <div className="pt-2">
              <div
                data-aos="fade-left"
                className=" flex flex-wrap items-center gap-1 text-white font-DMSans text-xl font-medium"
              >
                <p>Bank: </p>
                <p>ACCESS BANK</p>
              </div>
            </div>
            {/* <div data-aos="fade-left" className=" mt-10">
              <Button
                text="United Bank PLC"
                css={
                  " w-[245px] h-[50px] rounded-full bg-[#fff] font-DMSans text-lg text-[#1B1B1B] font-medium"
                }
              />
            </div> */}
          </div>
        </div>
        <div data-aos="fade-right" className=" lg:1/2 w-full">
          <img src={accimg} alt="" />
        </div>
      </div>

      <div className="lg:flex md:grid grid justify-center items-center py-10 mt-10 lg:gap-28 gap-16 lg:pl-0 lg:pr-24 md:px-10 px-8">
        <div data-aos="fade-down" className=" lg:w-1/2 w-full">
          <img src={donateimg} alt="" />
        </div>
        <div data-aos="fade-up" className=" lg:w-1/2 w-full ">
          <div className=" space-y-8">
            <p className=" text-[#1E0E62] lg:text-4xl md:text-4xl text-3xl font-DMSans">
              Let’s Build Our Community Together!
            </p>
            <p className="text-[#797897] lg:w-[480px] font-DMSans text-xl">
              Your donations fuel our mission! Every contribution helps empower
              individuals and strengthen our community. Together, we can create
              meaningful change. Donate today!
            </p>
            <Button
              onClick={() => navigate("/donate")}
              text="Donate"
              css={
                " px-6 text-[#fff] py-2 rounded-full bg-[#2F1893] font-DMSans text-xs font-medium"
              }
            />
          </div>
        </div>
      </div>

      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default Donate;
