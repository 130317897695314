import React, { useEffect, useState } from "react";
import volbg from "../assets/volbg.png";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/home/footer";
import Aos from "aos";
import "aos/dist/aos.css";

const Volunteer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  const [activeTab, setActiveTab] = useState("register");
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div
        data-aos="zoom-in"
        className=" bg-cover bg-center items-center justify-center bg-[#2F1893] bg-opacity-100 "
        style={{ backgroundImage: `url(${volbg})` }}
      >
        <div
          className="container lg:px-44 md:px-10 px-8 mx-auto py-8 flex flex-col lg:flex-row items-center justify-cventer gap-20 min-h-screen bg-cover bg-center"
          style={{ backgroundImage: `url('your-image-url')` }}
        >
          {/* Left Side: Text Content */}
          <div className="lg:w-1/2 w-full text-white lg:mt-20">
            <p className="lg:text-4xl md:text-6xl text-4xl space-y-2 font-DMSans leading-8 font-bold mb-4 lg:w-[300px] md:w-[500px] w-full">
              <p>Join a community</p>
              <p>of sustainable</p>
              <p>growth today!</p>
            </p>
            <p className="text-sm font-NunitoSans mb-6 lg:w-[370px] font-extralight leading-6">
              We believe that sustainable development starts from within the
              community. By fostering local leadership and involving community
              members in every stage of the project—from planning to
              implementation to evaluation—we ensure that the solutions are
              culturally relevant, practical, and have the buy-in necessary for
              long-term success.
            </p>
          </div>

          {/* Right Side: Registration Form */}
          <div className="lg:w-[530px] w-full pb-20 bg-white rounded-2xl p-8 shadow-lg">
            <div className="flex justify-between mb-6">
              <button
                className={`text-sm font-semibold pb-3 uppercase tracking-widest ${
                  activeTab === "register"
                    ? "text-[#1E0E62] border-b-2 border-[#1E0E62]"
                    : "text-[A1A1B0]"
                }`}
                onClick={() => setActiveTab("register")}
              >
                Register to be a Volunteer
              </button>
              <button
                className={`text-sm font-semibold pb-3 uppercase tracking-widest ${
                  activeTab === "login"
                    ? "text-gray-900 border-b-2 border-indigo-500"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("login")}
              >
                Login
              </button>
            </div>

            {activeTab === "register" ? (
              <form>
                <div className=" pt-10 mb-4">
                  <input
                    type="email"
                    placeholder="Your email"
                    className=" lg:w-[330px] md:w-[330px] rounded-full w-full border-[2px] border-[#EBEAED]  p-3 focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    placeholder="Your password"
                    className=" lg:w-[330px] md:w-[330px] rounded-full w-full border-[2px] border-[#EBEAED] p-3 focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div className=" grid justify-center items-center w-full py-10">
                  <button
                    type="submit"
                    className=" lg:w-[330px] md:w-[300px] rounded-full w-[200px]  bg-green-500 text-white py-3 hover:bg-green-600 transition duration-300"
                  >
                    Register
                  </button>
                </div>
              </form>
            ) : (
              <form>
                <div className="mb-4 pt-10">
                  <input
                    type="email"
                    placeholder="Your email"
                    className="lg:w-[330px] md:w-[330px] rounded-full w-full border-[2px] border-[#EBEAED]  p-3 focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    placeholder="Your password"
                    className="lg:w-[330px] md:w-[330px] rounded-full w-full border-[2px] border-[#EBEAED]  p-3 focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div className=" grid justify-center items-center py-10">
                  <button
                    type="submit"
                    className=" lg:w-[330px] md:w-[300px] rounded-full  w-[200px] bg-green-500 text-white py-3 hover:bg-green-600 transition duration-300"
                  >
                    Login
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Volunteer;
