import React from "react";
import Button from "../buttons";
import rightCol from "../../assets/rightCol.png";

const Community = () => {
  return (
    <div>
      <div className="lg:px-24 md:px-10 px-8 bg-[#F1F5F9] gap-10 py-16  lg:flex md:grid grid items-center justify-center">
        <div data-aos="fade-right" className=" lg:w-1/2 md:w-full w-full">
          <div>
            <p className="text-stroke text-primary Satoshi lg:text-5xl md:text-4xl text-3xl font-extrabold">
              OUR VALUABLE COMMUNITY
            </p>
            <p className=" Slight font-light text-lg text-[#0F172A] py-5">
              Our team is made up of dedicated professionals, volunteers, and
              partners who share a passion for sustainable development. With
              diverse backgrounds and expertise, we work together to implement
              innovative solutions that address the unique challenges faced by
              each community.
            </p>
            <Button
              css={
                "bg-[#4CAF50] w-[225px] h-[64px] text-[#fff] text-lg rounded-md"
              }
              text="Get Started"
            />
          </div>
        </div>
        <div data-aos="fade-left" className=" lg:w-1/2 md:w-full w-full">
          <img src={rightCol} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Community;
