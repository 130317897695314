import React from "react";
import Logo from "../../assets/CEFCODEVLOGOtwo.jpg";
import { FaFacebookSquare, FaGooglePlusG, FaTwitter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div data-aos="fade-down" className=" space-y-5 lg:py-0 md:py-5 py-5">
      <div className=" grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 lg:gap-0 md:gap-16 gap-16">
        <div className=" flex justify-center ">
          <ul className=" flex items-center gap-10">
            <li className=" text-[#1E0E62] text-base font-DMSans font-normal">
              Home
            </li>
            <li className=" text-[#1E0E62] text-base font-DMSans font-normal">
              About Us
            </li>
            <li className=" text-[#1E0E62] text-base font-DMSans font-normal">
              Terms
            </li>
          </ul>
        </div>
        <div className=" grid justify-center">
          <div className="">
            <div className=" grid justify-center">
              <img
                onClick={() => navigate("/home")}
                className=" cursor-pointer w-28 h-16"
                src={Logo}
                alt=""
              />
            </div>
            <div className=" grid justify-center">
              <p className=" lg:w-[375px] text-center font-DMSans text-[#A1A1B0] font-extralight">
                The Cephas Foundation for Sustainable Development (CFSD) is a
                non-profit organization dedicated to fostering sustainable
                community-driven development.
              </p>
            </div>
          </div>
        </div>
        <div className=" flex justify-center ">
          <ul className=" flex items-center gap-10">
            <li className=" text-[#1E0E62] text-base font-DMSans font-normal">
              Contacts
            </li>
            <FaTwitter className=" text-[#1E0E62]" size={16} />
            <FaFacebookSquare className=" text-[#1E0E62]" size={16} />
            <FaGooglePlusG className=" text-[#1E0E62]" size={16} />
          </ul>
        </div>
      </div>
      <div className=" grid justify-center items-center">
        <p className=" text-[#1E0E62] font-DMSans text-base font-normal text-center">
          © 2024 Cephas Foundation. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
